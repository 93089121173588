<template>
  <div class="fragment">
    <div class="cus-tooltip" :id="'id_' + id">
      <div class="cus-tooltip-full-text">
        <slot name="main-text"></slot>
      </div>
      <div class="cus-tooltip-popover" :style="{minWidth: '55px', width: widthContent+'px', marginLeft: (marginLeftPopover ? marginLeftPopover : marginLeft)+'px'}">
        <div class="tooltip-arrow popover-arrow" :style="{left: arrowLeft + '%'}"></div>
        <div class="cus-tooltip-popover-inner" v-bind:class="{'initial-white-space': widthContent > 380}">
          <slot name="popover"></slot>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CustomTooltip",

  props: {
    widthPopover:{
      type: [Number],
      default: null,
    },
    marginLeftPopover:{
      type: [Number],
      default: null,
    },
  },

  data() {
    return {
      id: this._uid,
      widthContent: null,
      marginLeft: null,
      arrowLeft: 35,
    }
  },

  mounted() {
    this.getPopoverWidth()
  },

  methods: {
    getPopoverWidth() {
      let withPopover = document.getElementById('id_' + this._uid).querySelector('.cus-tooltip-popover').offsetWidth
      let withPopoverContent = document.getElementById('id_' + this._uid).querySelector('.cus-tooltip-popover-inner').offsetWidth

      if(withPopover - 32 < 10) {
        if(this.widthPopover) {
          this.widthContent = this.widthPopover
        } else {
          this.widthContent = withPopoverContent + 35
        }
        // this.arrowLeft = 15
        this.arrowLeft = 5
        return this.marginLeft = -15
      }

      if(withPopover - 32 > withPopoverContent) {
        if(this.widthPopover) {
          this.widthContent = this.widthPopover
        } else {
          this.widthContent = withPopoverContent + 35
        }
        this.arrowLeft = 5
        return this.marginLeft = -5
      }

      if(withPopoverContent + 35 > withPopover) {
        if(this.widthPopover) {
          this.widthContent = this.widthPopover
        } else {
          this.widthContent = withPopoverContent + 35
        }
        this.marginLeft = -(withPopoverContent / 3)
        return
      }

      if(this.widthPopover) {
        this.widthContent = this.widthPopover
      } else {
        this.widthContent = withPopover
      }
      return this.marginLeft = -17

    }
  }

}
</script>

<style scoped>

.cus-tooltip {
  position: relative;
}

.initial-white-space >>> * {
  white-space: initial;
}

.cus-tooltip-popover {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 15px;
  background: white;
  border-radius: 10px;
  padding: 8px 15px;
  max-width: 380px;
  width: 160%;
  /*width: auto;*/
  margin-left: -17%;
  border: 1px solid #F8F4EE;
  box-shadow: 0px 4px 7px #ece5dc;
  transition: 0.3s;

  visibility: hidden;
  opacity: 0;
  z-index: -100;
  white-space: normal;
}

.cus-tooltip:hover .cus-tooltip-popover {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

.tooltip-arrow.popover-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(35%);
  margin-top: 0;
  margin-bottom: 0;
  transform: rotate(135deg);
  border-right-color: white !important;
  bottom: -8px;
  border-color: #ffffff !important;
  border-width: 10px 10px 10px 10px;
  border-radius: 0 3px 0 0;
  box-shadow: 4px -4px 7px -2px #ece5dc;
}

.tooltip-helper-paragraph {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #232323;
  color: #8F7A61;
  font-size: 11px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 8px;
  /*word-break: break-word;*/
}

.tooltip-helper-title {
  color: #8f7a61;
  font-size: 11px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 8px;
  /*word-break: break-word;*/
}

.cus-tooltip-popover-inner {
  display: inline-block;
  word-break: break-word;
}

.cus-tooltip-popover-inner > p {
  display: inline-block;
}

</style>